import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const baseStyle = {
    maxW: ['calc(100% - 16px)', null, 'calc(100% - 32px)', '1440px'],
    paddingX: ['16px', null, '32px', null, 'auto'],
    paddingY: ['16px', null, '32px', null, 'auto']
}

// define custom sizes
const sizes = {
  sm: defineStyle({
    maxW: '45ch',
  }),
  md: defineStyle({
    maxW: 'container.sm',
  }),
  lg: defineStyle({
    maxW: '1440px',
    marginX: '32px'
  }),
}

// export the component theme
export const Container = defineStyleConfig({ baseStyle, ...sizes })