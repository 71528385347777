import { defineStyleConfig } from '@chakra-ui/react'

export const Link = defineStyleConfig({
  transitionProperty: "common",
  transitionDuration: "fast",
  transitionTimingFunction: "ease-out",
  cursor: "pointer",
  textDecoration: "none",
  outline: "none",
  color: "blue.900",
  _hover: {
    textDecoration: "underline",
  },
  _focusVisible: {
    boxShadow: "outline",
  },
  variants: {
    white: {
      color: 'white',
    },
    green: {
      color: 'green.500'
    }
  },
})