import { defineStyleConfig } from '@chakra-ui/react'

export const Button = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderRadius: 'base', // <-- border radius is same for all variants and sizes
  },
  // Two sizes: sm and md
  sizes: {
    sm: {
      fontSize: 'sm',
      px: 4, // <-- px is short for paddingLeft and paddingRight
      py: 3, // <-- py is short for paddingTop and paddingBottom
    },
    md: {
      fontSize: 'md',
      px: 6, // <-- these values are tokens from the design system
      py: 4, // <-- these values are tokens from the design system
    },
  },
  // Two variants: outline and solid
  variants: {
    outline: {
      border: '2px solid',
      borderColor: 'white',
      color: 'white',
    },
    greenGradient: {
      bgGradient: 'linear(to-br, #82BE41, #A1E05D)',
      color: 'white',
      _hover: {
        bg: 'green.100'
      }
    },
    darkBlue: {
      bg: 'blue.900',
      color: 'white',
      _hover: {
        bg: 'blue.500',
      }
    },
    darkBlueOutline: {
      bg: 'transparent',
      color: 'blue.900',
      borderColor: 'blue.900',
      border: '2px solid',
      _hover: {
        borderColor: 'white.500',
        color: 'white.500'
      }
    },
    green: {
      bg: 'green.500',
      color: 'white',
      _hover: {
        bg: 'green.100',
      }
    },
    white: {
      bg: 'white',
      color: 'blue.500',
      border: '1px solid',
      borderColor: 'white',
      _hover: {
        bg: 'transparent',
        color: 'white',
        border: '1px solid',
        borderColor: 'white'
      }
    },
    whiteOnWhite: {
      bg: 'white',
      color: 'blue.500',
      border: '1px solid',
      borderColor: 'white',
      boxShadow: '0px 1px 2px 0px #1018280D',
      _hover: {
        bg: 'blue.900',
        color: 'white',
        border: '1px solid',
        borderColor: 'white'
      }
    }
  },
  // The default size and variant values
  defaultProps: {
    size: 'md',
    variant: 'darkBlue',
  },
})