// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme } from '@chakra-ui/react';
import { Button } from './button';
import { Link } from './link';
import { Container } from './container';

export const baseTheme = {
  colors: {
    blue: {
      100: '#1D7CF2',
      500: "#29398E",
      900: '#07176D', // purple
    },
    green: {
      100: "#A1E05D",
      500: "#82BE41",
      900: "#82BE41"
    },
    white: {
      500: '#fff'
    },
    gray: {
      500: '#fafafa'
    }
  },
  layerStyles: {
    base: {
      bg: 'white',
      color: 'blue.900',
    },
    green: {
      color: 'green.500',
    },
    darkBlue: {
      color: 'white',
      bg: 'blue.900'
    }
  },
  
  textStyles: {
    html: {
      fontSize: 'calc(16px + 0.390625vw)',
      my: '24px',
      color: 'blue.900'
    },
    h1: {
      my: '24px'
    },
    h2: {
      my: 2,
      fontSize: '100px'
    },
    h3: {
      my: 4
    },
    h4:  {
      my: 4
    },
    h5:  {
      my: 4
    },
    h6:  {
      my: 4
    },
    p:  {
      mt: 3,
      mb: 6,
      color: 'blue.900',
    },
    eyebrow: {
      fontWeight: 'medium',
      fontSize: 'sm',
      fontStyle: 'italic',
      textTransform: 'uppercase',
      my: 2
    },
    testimonials: {
      fontWeight: 'bold',
      fontSize: 'md',
      color: 'blue.900',
    },
    attribution: {
      fontWeight: 'medium',
      textStyles: 'italic',
      fontSize: 'sm',
      color: 'blue.900',
    },
    li: {
      marginLeft: '40px'
    }
  },
  styles: {
    root: {
      color: 'blue.900',
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body'
    },
    h1: {
      color: 'blue.900',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: ['body', 'heading'],
      fontSize: [3, 5, 6]
    },
    h2: {
      color: 'blue.900',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      textDecoration: 'underline',
      fontSize: [3, 5]
    },
    h3: {
      color: 'blue.900',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: [2, 4]
    },
    h4: {
      color: 'blue.900',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 2
    },
    h5: {
      color: 'blue.900',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 1
    },
    h6: {
      color: 'blue.900',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 0
    },
    p: {
      color: 'blue.900',
      fontFamily: 'body',
      fontSize: 3,
      fontWeight: 'body',
      lineHeight: 'body',
      margin: 4
    },
    a: {
      color: 'blue.900',
      '&:visited': {
        color: 'primary'
      }
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit'
      }
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit'
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid'
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid'
    },
    img: {
      maxWidth: '100%'
    },
    li: {
      marginLeft: '40px'
    }
  },
  components: {
    Button,
    Container,
    Link
  }
}

export default extendTheme(baseTheme)